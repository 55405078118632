import { auth, AuthTokenRefreshFailed } from '@/auth';

import axios, {
  AxiosRequestConfig,
  Method,
  AxiosError,
  AxiosResponse,
} from 'axios';
import axiosRetry from 'axios-retry';
import { addBreadcrumbToSentry } from '@/utils/sentry';
import { ClientErrorCode } from '@/store/errors';
import { logger } from '@/utils';
import { IAxiosCacheAdapterOptions, setup } from 'axios-cache-adapter';
import { minutesToMilliseconds } from 'date-fns';

const DEFAULT_AXIOS_TIMEOUT_MS = 60000;
const AXIOS_RETRY_COUNT = 3;

interface CallOptions
  extends Omit<AxiosRequestConfig, 'url' | 'data' | 'method'> {
  allowUnauthenticated?: boolean;
}

interface RequestInput {
  url: string;
  data?: unknown;
  options?: CallOptions;
  retryOnFail?: boolean;
}

const CACHE_OPTIONS: IAxiosCacheAdapterOptions = {
  // Caching is enabled by default, turning it off globlaly
  ignoreCache: true,
  // Maximum time for storing each request in milliseconds, defaults to 15 minutes.
  maxAge: minutesToMilliseconds(5),
  // NOTE: Enable caching for GET requests with query parameters, which are ignored by default.
  // This allows responses to be cached based on varying query parameter values.
  exclude: { query: false },
  // NOTE: Custom cache key generation to include query parameters in the cache key.
  // Ensures that requests are cached and retrieved based on both the URL and query parameters,
  // so requests with unique parameters are stored independently.
  key: (req) => {
    if (req.url && req.params) {
      return req.url + JSON.stringify(req.params);
    }
    return req.url ?? '';
  },
};

const noRetryClient = setup({
  baseURL: GLOBALCONFIG.appBackendURL,
  cache: CACHE_OPTIONS,
});

const retryClient = setup({
  baseURL: GLOBALCONFIG.appBackendURL,
  cache: CACHE_OPTIONS,
});

// This function configures a retry mechanism for Axios requests.
// It retries on server errors (status code 500 and above) and network/CORS issues,
// which often appear in Sentry with a status code of 0.
axiosRetry(retryClient, {
  retries: AXIOS_RETRY_COUNT,
  retryDelay: axiosRetry.exponentialDelay,
  retryCondition: (error) => {
    // Conditions to trigger a retry:
    // 1. Server error with status code 500 or above
    // 2. Network or CORS error (e.g., ECONNABORTED, ERR_NETWORK, Network Error)
    return (
      (error.response !== undefined && error.response.status >= 500) ||
      error.code === 'ECONNABORTED' ||
      error.code === 'ERR_NETWORK' ||
      (error.response === undefined && error.message === 'Network Error')
    );
  },
  shouldResetTimeout: true, // Resets the timeout for each retry attempt
});

const call =
  (method: Method, timeout = DEFAULT_AXIOS_TIMEOUT_MS) =>
  async <ResponseType>(
    requestInput: RequestInput
  ): Promise<AxiosResponse<ResponseType>> => {
    const { url, data, options, retryOnFail = false } = requestInput;

    addBreadcrumbToSentry({ url, method });
    if (data !== undefined) addBreadcrumbToSentry({ data });
    if (options !== undefined) addBreadcrumbToSentry({ options });

    const headers: Record<string, string> = {
      'Content-Type': 'application/json',
    };

    if (!options?.allowUnauthenticated) {
      let accessToken = auth.getAccessToken();
      // NOTE: If the access token is not set, we first try to refresh it
      // and, if failed, sign out a user
      if (accessToken) {
        addBreadcrumbToSentry({
          message: 'Access token not found, attempting refresh',
        });

        accessToken = await auth.refreshAccessToken();

        if (!accessToken) {
          await auth.signOut();
          throw new AuthTokenRefreshFailed();
        }
      }

      headers.Authorization = `Bearer ${accessToken}`;
    }

    const client = retryOnFail ? retryClient : noRetryClient;

    try {
      return await client({
        timeout,
        method,
        url,
        data,
        headers: {
          ...headers,
          ...options?.headers,
        },
        validateStatus: (status: number) => status >= 200 && status < 400,
        ...options,
      });
    } catch (err) {
      if (!isAxiosRequestAbortedError(err)) {
        logger.error(err);
      }
      throw err;
    }
  };

const makeApi = (timeout = DEFAULT_AXIOS_TIMEOUT_MS) => ({
  get: call('get', timeout),
  post: call('post', timeout),
  put: call('put', timeout),
  patch: call('patch', timeout),
  delete: call('delete', timeout),
});

const apiClient = makeApi();

const isAxiosError = (error: unknown): error is AxiosError =>
  axios.isAxiosError(error);

const isAxiosConnectionAbortedError = (error: unknown): error is AxiosError => {
  return (
    isAxiosError(error) &&
    error.code === ClientErrorCode.AXIOS_CONNECTION_ABORTED_ERROR
  );
};

const isAxiosRequestAbortedError = (error: unknown): error is AxiosError => {
  return isAxiosError(error) && error.code === ClientErrorCode.REQUEST_ABORTED;
};

const isAxiosTooManyRequestsError = (error: unknown): error is AxiosError => {
  return isAxiosError(error) && error.response?.status === 429;
};

export {
  apiClient,
  isAxiosError,
  noRetryClient,
  retryClient,
  isAxiosConnectionAbortedError,
  isAxiosRequestAbortedError,
  isAxiosTooManyRequestsError,
};
